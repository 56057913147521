<template>
  <IconBase :height="height"
            :width="width"
            :icon-color="iconColor">
    <g>
<polygon points="52,34.6 53.7,37.5 54.2,37.5 53.9,34.6"/>
<path d="M25.8,43c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8c0-2.5-1.6-4.7-3.9-5.5h0.5c1.2,0,2.2-1,2.3-2.2l0.8-13.9
c0-0.6-0.2-1.3-0.6-1.7c-0.4-0.5-1-0.7-1.7-0.7h-6.5c-0.6,0-1.2,0.3-1.7,0.7c-0.4,0.5-0.7,1.1-0.6,1.7l0.8,13.9
c0.1,1.2,1.1,2.2,2.3,2.2h0.5C27.5,38.3,25.8,40.5,25.8,43z M29.2,35.3c-0.1,0-0.1-0.1-0.1-0.1l-0.8-13.9c0,0,0-0.1,0-0.1
c0,0,0.1,0,0.1,0h6.5c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1l-0.8,13.9c0,0.1-0.1,0.1-0.1,0.1H29.2z M31.6,39.4c2,0,3.6,1.6,3.6,3.6
s-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6S29.7,39.4,31.6,39.4z"/>
<path d="M46.5,53.4H7.1c-1.4,0-2.6-0.7-3.3-1.9c-0.7-1.2-0.7-2.6,0-3.8L28.3,5c0.7-1.2,1.9-1.9,3.3-1.9C33,3.1,34.3,3.8,35,5
l14,24.3h2.5L36.8,3.9c-1.1-1.9-3-3-5.2-3c-2.2,0-4.1,1.1-5.2,3L1.9,46.5c-1.1,1.9-1.1,4.1,0,6c1.1,1.9,3,3,5.2,3h39.5V53.4z"/>
<path d="M61.4,46.5l-1.5-2.6c0,1.1-0.3,2.2-0.7,3.2l0.3,0.5c0.7,1.2,0.7,2.6,0,3.8c-0.7,1.2-1.9,1.9-3.3,1.9h-3.4v2.2h3.4
c2.2,0,4.1-1.1,5.2-3C62.5,50.7,62.5,48.4,61.4,46.5z"/>
</g>
<path d="M54.3,60h-2.5v-7.5c4.4-1,7.5-5.1,7.1-9.8l-1-11.3c0-0.5-0.5-0.9-1-0.9H42.6c-0.5,0-1,0.4-1,0.9l-1,11.3
c-0.4,4.7,2.7,8.8,7.1,9.8V60h-2.5c-1.4,0-2.5,1.1-2.5,2.5c0,0.3,0.2,0.5,0.5,0.5h13.2c0.3,0,0.5-0.2,0.5-0.5
C56.8,61.1,55.7,60,54.3,60z M44.4,33.5h10.5l0.5,5.1H44L44.4,33.5z"/>
  </IconBase>
</template>
<script>

import IconBase from '@/icons/IconBase'
export default {
  name: 'DangerIcon',
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
