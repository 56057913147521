var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.toDisplay),expression:"toDisplay"}],staticClass:"step",style:({ pointerEvents: _vm.isActive ? 'all' : 'none' })},[_c('div',{staticClass:"slot image",style:({
          transform: `translateX(${_vm.translateImage})`,
          transition: _vm.noTransition ? 'none' : ''
        })},[_vm._t("image")],2),_c('div',{staticClass:"slot description",style:({
        opacity: _vm.opacity,
        transition: _vm.noTransition ? 'none' : ''
      })},[_vm._t("description")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }