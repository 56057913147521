<template>
  <IconBase :height="height"
            :width="width"
            :icon-color="iconColor">
<path d="M27,0h10c1.7,0,3,1.3,3,3v21h11c2.2,0,3.3,2.7,1.8,4.3l-19,19c-0.9,0.9-2.5,0.9-3.4,0l-19-19C9.7,26.7,10.8,24,13,24h11V3
C24,1.3,25.3,0,27,0z M64,47v14c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3V47c0-1.7,1.3-3,3-3h18.3l6.1,6.1c2.5,2.5,6.6,2.5,9.1,0l6.1-6.1
H61C62.7,44,64,45.3,64,47z M48.5,58c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5S48.5,59.4,48.5,58z M56.5,58
c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5S56.5,59.4,56.5,58z"/>
  </IconBase>
</template>
<script>

import IconBase from '@/icons/IconBase'
export default {
  name: 'InstallIcon',
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
