var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Content',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStep ==='settings'),expression:"currentStep ==='settings'"}],staticClass:"settings-wrapper"},[_c('Settings',{attrs:{"title":_vm.$t('ui.gameSettings'),"fields":[
          // {
          //   name: 'linesNum',
          //   type: 'input',
          //   inputType: 'number',
          //   label: $t('ui.linesNum'),
          //   min: '4',
          //   initial: 6,
          //   required: true
          // },
          {
            name: 'linesNum',
            type: 'select',
            label: _vm.$t('ui.linesNum'),
            required: true,
            value: 6,
            options: [
              {
                value: 5,
                name: _vm.$t('game.grapeSize.verysmall.name'),
                hint: _vm.$t('game.grapeSize.verysmall.hint')
              },
              {
                value: 6,
                name: _vm.$t('game.grapeSize.small.name'),
                hint: _vm.$t('game.grapeSize.small.hint')
              },
              {
                value: 7,
                name: _vm.$t('game.grapeSize.normal.name'),
                hint: _vm.$t('game.grapeSize.normal.hint')
              },
              {
                value: 8,
                name: _vm.$t('game.grapeSize.big.name'),
                hint: _vm.$t('game.grapeSize.big.hint')
              },
              {
                value: 9,
                name: _vm.$t('game.grapeSize.verybig.name'),
                hint: _vm.$t('game.grapeSize.verybig.hint')
              },
              {
                value: 10,
                name: _vm.$t('game.grapeSize.huge.name'),
                hint: _vm.$t('game.grapeSize.huge.hint')
              },
              {
                value: 11,
                name: _vm.$t('game.grapeSize.veryhuge.name'),
                hint: _vm.$t('game.grapeSize.veryhuge.hint')
              }
            ]
          },
          {
            name: 'difficulty',
            type: 'select',
            label: _vm.$t('game.difficulty.label'),
            placeholder: _vm.$t('game.difficulty.placeholder'),
            defaultHint: _vm.$t('game.difficulty.hint'),
            required: true,
            value: '0.5',
            options: [
              {
                value: '0.25',
                name: _vm.$t('game.difficulty.easy.name'),
                hint: _vm.$t('game.difficulty.easy.hint')
              },
              {
                value: '0.5',
                name: _vm.$t('game.difficulty.normal.name'),
                hint: _vm.$t('game.difficulty.normal.hint')
              },
              {
                value: '1',
                name: _vm.$t('game.difficulty.difficult.name'),
                hint: _vm.$t('game.difficulty.difficult.hint')
              },
              {
                value: '2',
                name: _vm.$t('game.difficulty.hardcore.name'),
                hint: _vm.$t('game.difficulty.hardcore.hint')
              }
            ]
          },
        ]},on:{"start-game":_vm.startGame}})],1),(_vm.currentStep ==='playing')?_c('GameGroup',{attrs:{"gameSettings":_vm.gameSettings},on:{"start-over":_vm.newGame}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }