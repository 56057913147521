<template>
  <div class="glass">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 306 512" style="overflow:visible;enable-background:new 0 0 306 512;" xml:space="preserve">
<g><path class="st0" d="M218.3,326.2c0.7-0.3,0.2-1.8,0.9-2.1c19.6-9.7,36.6-22.6,51.9-41.1c22.2-26.8,28.9-66.8,28.3-105.3
    c0-1.2,1.2-4.5,1.2-5.7c-0.4-14.9-1.7-29.2-3.3-41.8C292.9,96,274.5,32.9,264.2,0H40.5C30.2,32.9,11.8,96,7.3,130.2
    C6.1,140,5,150.9,4.4,162.3c0,0.7,1.2,3.2,1.2,4C3.7,207.5,8.3,252.6,32.6,282c15.5,18.7,32.8,32.1,52.7,41.8
    c0.4,0.2,0.7,2.2,1.1,2.4c26.8,12.8,44,39.8,44,69.5v9.9c0,37.4-25.8,70.1-62.3,77.9c-1,0.2-2.1,0.4-3.1,0.6c0,0-27.8,4-33.1,27.8
    h241.1c-5.3-23.8-33.1-27.8-33.1-27.8c-1.1-0.2-2.1-0.4-3.1-0.6c-36.5-7.8-62.3-40.6-62.3-77.9v-9.9
    C174.3,366,191.5,339,218.3,326.2L218.3,326.2z"/>
  <text v-if="annotation.length > 0" x="153" y="200" width="246" class="annotation-text-source" text-anchor="middle">{{ annotation }}</text>
  <g>
    <defs>
      <path id="SVGID_1_" d="M235.1,316.8c16.4-12.7,20.3-15.2,36.8-32.2c22.2-26.8,29.4-66.1,28.8-104.6c0-1.2-0.1-6.9-0.1-8.1
        c-0.4-14.9-1.7-29.2-3.3-41.8C292.9,96,274.5,32.9,264.2,0H40.5C30.2,32.9,11.8,96,7.3,130.2C6.1,140,5,150.9,4.4,162.3
        c0,0.7-0.5,18.4-0.5,19.1c1.6,47.8,4.1,73.3,28.5,102.7c15.5,18.7,35.3,32.9,55.2,42.6c0,0,20.6,12.3,67,12.3
        S235.1,316.8,235.1,316.8z"/>
    </defs>
    <clipPath id="SVGID_2_">
      <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
    </clipPath>
    <rect :y="wineY" class="st1 wine_1" width="306" height="340"/>
  </g>
  <g v-if="annotation.length > 0">
    <defs>
      <text id="SVGID_5_" x="153" y="200" width="246" class="annotation-text-source" text-anchor="middle" v-text="annotation"></text>
    </defs>
    <clipPath id="SVGID_6_">
      <use xlink:href="#SVGID_5_"  style="overflow:visible;"/>
    </clipPath>
    <rect :y="wineY" class="annotation-text wine_1" width="306" height="340"/>
  </g>
  <path class="st2" d="M174.3,395.7v9.9c0,37.4,25.8,70.1,62.3,77.9c1,0.2,2.1,0.4,3.1,0.6c0,0,27.8,4,33.1,27.8h-20.8
    c-3.4-14.5-24.7-14.9-36.3-17.3c-36.5-7.8-62.3-51.7-62.3-89v-9.9c0-29.7,17.2-65.9,44-78.8c20.4-9.7,39.2-24.2,55-43.3
    c31.6-38.1,29.9-97.8,24-143.4C272.1,96,253.7,32.9,243.4,0h20.8c10.3,32.9,28.7,96,33.1,130.2c5.9,45.6,7.6,114.6-24,152.7
    c-15.9,19.1-34.7,33.6-55,43.3C191.5,339,174.3,366,174.3,395.7L174.3,395.7z"/>
  <path class="st3" d="M273.3,282.9c-16.1,19.4-35.2,34-55.9,43.7c-0.2,0.1-0.4,0.2-0.5,0.2c-0.7,0.3-1.4,0.6-2,0.9
    c-22.4,11.3-44.8,11.3-68.3,11.3c-17.1,0-34.7-3.5-51-8.9c0,0,0,0-0.1,0c-23.8-9.6-46-25.4-64.2-47.3C6.5,253,2.3,204,4.4,162.3
    c38.4-1.8,76.7,13.2,113.2,27.2c42,16.1,88.2,31.2,131.2,18.2c20.4-6.2,38.4-19.1,51.7-35.7c0,0,0,0,0,0
    C301.6,211.3,296.3,255.2,273.3,282.9L273.3,282.9z"/>
  <path class="st4" d="M300,201.8c-0.9,12.8-2.7,25.5-5.9,37.9c-1,3.7-2.1,7.4-3.3,11.1c-4,11.6-9.6,22.7-17.4,32.2
    c-3.8,4.6-7.9,9.1-12.2,13.2c-10.6,10.3-22.3,19.1-35.3,26.2c-0.6,0.3-1.2,0.7-1.9,1c-3,1.6-6.1,2.9-9.1,4.5
    c-14,7.1-28,9.7-42.2,10.7c6.8-9.3,15.2-17,24.9-21.6c20.4-9.7,39.2-24.2,55-43.3c18.4-22.2,25.5-51.7,27.1-81.5
    c7.8-5.7,14.9-12.6,21-20.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,1.1,0.1,2.3,0.1,3.4c0,0.5,0,1.1,0,1.6c0,5,0,10-0.2,15
    C300.4,195.3,300.3,198.5,300,201.8L300,201.8z"/>
</g>
</svg>
  </div>
</template>

<script>
export default {
  name: 'Glass',
  props: {
    progress: {
      required: true,
      type: Number,
      default: 0
    },
    annotation: {
      required: false,
      type: String,
      default: ''
    }
  },
  computed: {
    wineY () {
      const val = (340 - (this.progress * 340)).toFixed(5)
      return val === 'NaN' ? 0 : val
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/scss/globals';

  .st0{fill:#CAE7EF;}
  .st1{clip-path:url(#SVGID_2_);fill:#5D73D8;}
  .st2{opacity:0.38;fill:#A2D7E0;}
  .st3{display:none;fill:#E94444;}
  .st4{display:none;fill:#D83131;}
  .st5{clip-path:url(#SVGID_4_);fill:#5D73D8;}
  .invisible{fill: transparent}
  .annotation-text {
    clip-path:url(#SVGID_6_);fill: #fff;
  }
  .annotation-text-source {
    font-size: 8em;
    font-family: 'Mark Pro', sans-serif;
    font-weight: bold;
    fill: $theme-color1;
  }
  .glass {
    position: relative;
    width: 100%;
    height: auto;
    pointer-events: none;
    padding: 0;

    svg {
      height: 100%;
      width: 100%;
      display: block;
    }
    .wine_1 {
      transform-origin: bottom center;
      transition: all .5s $transition-easing;
    }

    .number {
      position: absolute;
      font-weight: bold;
      top: 0;
      left: 0;
      height: 65%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      font-size: 3em;
    }
  }
</style>
